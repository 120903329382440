import * as c from "../constants";

const initialState = () => {
    return {
        products: [],
        product: []
    }
}

export const productsReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.ADMIN_SET_PRODUCTS:
            return { ...state, products: action.payload }
        case c.ADMIN_SET_PRODUCT:
            return {...state, product: action.payload }
        case c.ADMIN_CHANGE_PRODUCT_STATUS:
            const { productId, status } = action;
            const updatedProducts = state.products.data.map((product) => {
                if (product.id === productId) {
                    return { ...product, status: status };
                }
                return product;
            });
            return { ...state, products: { ...state.products, data: updatedProducts } };
        case c.ADMIN_CHANGE_PRODUCT_HOMEPAGE:
            const { productIdd, homepage } = action;
            const updatedProductsH = state.products.data.map((product) => {
                if (product.id === productIdd) {
                    return { ...product, show_homepage: homepage };
                }
                return product;
            });
            return { ...state, products: { ...state.products, data: updatedProductsH } };
        default:
            return state
    }
}
