import React, { useState, useEffect }  from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../redux/actions/app";
import BeatLoader from 'react-spinners/BeatLoader';
import styles from './styles.module.scss';

const OurProject = () => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.app.categories);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(getCategories()).then(res => {
            setLoading(false);
        });
    }, [dispatch]);

    return (
        <div className={styles.OurProjectWrapper}>
            <div className="container">
                <div className="text-center">
                    <h6>
                        Our Project
                    </h6>
                    <h4>
                        Check out some of Our Award- winning Project
                    </h4>
                </div>
                <div className={styles.OurProjectItems}>
                    {loading ? (
                        <div className='text-center mt-5'>
                            <BeatLoader color="#EE3638" />
                        </div>
                    ) : (
                        <div className="row flex-nowrap overflow-auto">
                            {categories && categories.length > 0 ? (
                                categories.map(category => (
                                    <div className="col-md-4">
                                        <div className={styles.OurProjectsItem}>
                                            <img alt="Our-product-1" src={category.imageUrl} width="100%" />
                                            <h3>{category.name}</h3>
                                            <button>See Products</button>
                                            <hr></hr>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className='text-center my-5'><b>Jemi duke perditesuar produktet, ju lutem na vizitoni perseri!</b></p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OurProject;