import React from 'react';
import styles from './styles.module.scss';

const InfoSection = () => {
    return (
        <div className='container'>
            <div className={styles.InfoSectionWrapper}>
                <div className={styles.InfoSectionItemWrapper}>
                    <h3>01</h3>
                    <div>
                        <h4>DESIGN WITH PERFECTION</h4>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        </p>
                    </div>
                </div>
                <div className={styles.InfoSectionItemWrapper}>
                    <h3>02</h3>
                    <div>
                        <h4>PROFESSIONAL ARCHITECT</h4>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        </p>
                    </div>
                </div>
                <div className={styles.InfoSectionItemWrapper}>
                    <h3>03</h3>
                    <div>
                        <h4>BEST CLIENT SUPPORT</h4>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoSection;