import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProducts } from '../redux/actions/app';
import ProductsMainSection from '../components/products-main-section/products-main-section';
import Footer from '../components/footer/Footer';
import { useParams } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import styles from './styles.module.scss';

const AllProducts = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const products = useSelector((state) => state.app.products);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        dispatch(getProducts(id)).then(res => {
            setLoading(false);
        });
    }, [dispatch, id]);

    return (
        <div>
            <ProductsMainSection />
            <div className={styles.AllProductsWrapper}>
                <div className='container'>
                    <h5 className={styles.Title}>Check Out Our</h5>
                    <h4 className={styles.SubTitle}>Products</h4>

                    {loading ? (
                        <div className='text-center mt-5'>
                            <BeatLoader color="#EE3638" />
                        </div>
                    ) : (
                        <div>
                            {products && products.length > 0 ? (
                                <div className='row'>
                                    {products.map(product => (
                                        <div className='col-md-4 mt-3' onClick={() => navigate(`/product/${product.id}`)}>
                                            {product.image.imageUrl && (
                                                <img alt='Produkti' src={product.image.imageUrl} className={styles.AllProductImage} />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className='text-center'><b>Jemi duke perditesuar produktet, ju lutem na vizitoni perseri!</b></p>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AllProducts;