import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { adminPutProduct } from '../../../../redux/actions/products';
import styles from '../styles.module.scss';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast } from "react-toastify";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditProduct = (props) => {
    const dispatch = useDispatch();
    const { categories, onFinish, product } = props;

    const [name, setName] = useState(product ? product.name : '');
    const [category, setCategory] = useState(product ? product.category_id : '');
    const [code, setCode] = useState(product ? product.code : '');
    const [description, setDescription] = useState(product ? product.description : '');
    const [shortDescription, setShortDerscription] = useState(product ? product.short_description : '');
    const [price, setPrice] = useState(product ? product.price : '');

    const [loading, setLoading] = useState(false);

    const onSubmitHandler = useCallback((e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            "name": name,
            "category_id": category,
            "code": code,
            "description": description,
            "price": price,
            "short_description": shortDescription
        };

        dispatch(adminPutProduct(data, product.id)).then(res => {
            setLoading(false);
            toast.success('Produkti u ndryshua!');
            onFinish();
        }).catch(err => {
            console.log("error", err);
            toast.error(err.data.message);
            setLoading(false);
        });
    }, [dispatch, onFinish, category, name, code, description, price, product, shortDescription]);

    const onChangeDescription = (e, editor) => {
        setDescription(editor.getData())
    }

    return (
        <form onSubmit={onSubmitHandler} className={styles.NewProductWrapper}>
            <TextField
                label="Produkti"
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
                fullWidth
                value={name}
                size='small'
            />

            <FormControl variant="outlined" fullWidth={true} size="small">
                <InputLabel id="demo-simple-select-outlined-label">Kategoria</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    label="Kategoria"
                    required
                >
                    {categories && categories.map(category => (
                        <MenuItem value={category.id}>{category.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={onChangeDescription}
                config={{         
                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList']
                }}  
            />

            <TextField
                label="Kodi"
                type="text"
                onChange={(e) => setCode(e.target.value)}
                fullWidth
                value={code}
                size='small'
            />

            <TextField
                label="Çmimi"
                type="text"
                onChange={(e) => setPrice(e.target.value)}
                fullWidth
                value={price}
                size='small'
            />

            <TextField
                label="Pershkrim i shkurter per homepage"
                type="text"
                onChange={(e) => setShortDerscription(e.target.value)}
                fullWidth
                value={shortDescription}
                size='small'
                inputProps={{ maxLength: 150 }}
            />

            <div className='text-center'>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Ndrysho produktin
                </Button>
            </div>

            <div className='text-center'>
                {loading && (
                    <BeatLoader color="#EE3638" />
                )}
            </div>
        </form>
    );
};

export default EditProduct;