import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from '../admin-components/Navbar';

const AdminRoute = ({ children }) => {
    const user = useSelector((state) => state.auth.user);

    return user ? <div>
        <Navbar />
        <div style={{height: '100px'}}></div>
        {children}
    </div> : <Navigate to="/login" />
};

export default AdminRoute;