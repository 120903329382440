import React from 'react';
import styles from './styles.module.scss';
import image from '../../assets/contact.png';

const ContactSection = () => {

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    }

    return (
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className={styles.ContactSectionWrapperItem}>
                        <h4>Contact Us Here Now</h4>
                        <p>Faucibus in ornare quam vviverra. Lorem ipsum dolor sit amet</p>
                        <div>
                            <button className={styles.ContactButton} onClick={() => scrollToBottom()}>
                                Contact us
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <img className={styles.ContactImage} src={image} alt="About us imag" />
                </div>
            </div>
        </div>
    );
};

export default ContactSection;