import React from 'react';
import { Link } from "react-router-dom";
import logo from '../assets/grace-red-logo.png';
import styles from './styles.module.scss';

const Navbar = () => {

    return (
        <div className={styles.NavbarWrapper}>
            <div className='container'>
                <div>
                    <Link to="/dashboard"><img alt="regGraceLogo" src={logo} width="150px" /></Link>
                    <Link to="/admin-categories">Kategorite</Link>
                    <Link to="/admin-products">Produktet</Link>
                    <Link to="/admin-messages">Mesazhet</Link>
                </div>
            </div>
        </div>
    );
};

export default Navbar;