import React, { useState } from 'react';
import styles from './styles.module.scss';
import star from '../../assets/star.png';

const Customers = () => {
    const length = "03";
    const [activeCustomer, setActiveCustomer] = useState(0);

    const total = 2;
    const customers = [
        {
            number: "01",
            rate: "5.0",
            title: "1 Design Quality & Usability",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            name: "Jessica Locke"
        },
        {
            number: "02",
            rate: "4.9",
            title: "2 Design Quality & Usability",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            name: "Jessica Locke"
        },
        {
            number: "03",
            rate: "4.8",
            title: "3 Design Quality & Usability",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            name: "Jessica Locke"
        }
    ]

    const next = () => {
        if(activeCustomer < total) {
            setActiveCustomer(activeCustomer + 1);
        }
    }

    const back = () => {
        if(activeCustomer > 0) {
            setActiveCustomer(activeCustomer - 1);
        }
    }

    return (
        <div className='container mb-5'>
            <div className={styles.CustomersWrapper}>
                <div className="text-center">
                    <h6>
                        What they say
                    </h6>
                    <h4>
                        Our Happy Customer Says
                    </h4>
                </div>
                <div className={styles.CustomerItemWrapper}>
                    <div className={styles.Main}>
                        <div className={styles.Numbers}>
                            <p className={styles.Active}>{customers[activeCustomer].number}</p>
                            <div className={styles.Underline}></div>
                            <p>{length}</p>
                        </div>
                        <div className={styles.Arrows}>
                            <p onClick={() => back()}>&#x2190;</p>
                            <p onClick={() => next()} className={styles.RightArrow}>&#x2192;</p>
                        </div>
                    </div>
                    <div className={styles.Rate}>
                        <img src={star} alt="Star rate" /> <span>{customers[activeCustomer].rate}</span>
                    </div>
                    <div className={styles.Description}>
                        <h4>{customers[activeCustomer].title}</h4>
                        <p>{customers[activeCustomer].description}</p>
                        <h5>{customers[activeCustomer].name}</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Customers;