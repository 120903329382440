import React from 'react';
import MainSection from '../components/main-section/MainSection';
import InfoSection from '../components/info-section/InfoSection';
import AboutSection from '../components/about-section/AboutSection';
import OurMission from '../components/our-mission/OurMission';
import OurProject from '../components/our-project/OurProject';
import InspirationsDesign from '../components/inspirations-design/InspirationsDesign';
import ContactSection from '../components/contact/ContactSection';
import Customers from '../components/customers/Customers';
import Footer from '../components/footer/Footer';

const Home = () => {
    return (
        <div>
            <MainSection />
            <InfoSection />
            <AboutSection />
            <OurMission />
            <div className='container'>
                <hr></hr>
            </div>
            <OurProject />
            <InspirationsDesign />
            <ContactSection />
            <Customers />
            <Footer />
        </div>
    );
};

export default Home;