import React from 'react';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';
import aboutusImage from '../../assets/about-us-section.png';

const AboutSection = () => {
    const navigate = useNavigate();

    const goToAboutUs = () => {
        navigate('/about-us');
    }

    return (
        <div className='container mb-5'>
            <div className='row'>
                <div className='col-md-6'>
                    <img className={styles.AboutUsSectionImage} src={aboutusImage} alt="About us imag" />
                </div>
                <div className='col-md-6'>
                    <div className={styles.AboutWrapper}>
                        <div>
                            <h6>ABOUT US</h6>
                            <h4>NJE TITULL</h4>
                            <p>
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
                            </p>
                            <span>Contact Sales Agent</span>
                            <div>
                                <button className={styles.ContactNowButton} onClick={() => goToAboutUs()}>
                                    ABOUT US
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutSection;