import API from '../api';
import * as c from "../constants";

export const logoutUser = () => async(dispatch, getState) => {
    try {
        dispatch({ type: c.LOGOUT })

        localStorage.removeItem('user')
        localStorage.removeItem('token')

        return true
    } catch (error) {
        throw error
    }
}

export const login = (email, password) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.post('/login', {email, password}, config)

        localStorage.setItem('user', JSON.stringify(response.data.user))
        localStorage.setItem('token', JSON.stringify(response.data.accessToken))

        dispatch({ type: c.LOGIN, payload: response.data })

        return response;
    } catch (error) {
        console.log("err", error);
        throw error
    }
}