import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getCategoriesWithProducts } from '../redux/actions/app';
import ProductsMainSection from '../components/products-main-section/products-main-section';
import Footer from '../components/footer/Footer';
import BeatLoader from 'react-spinners/BeatLoader';
import styles from './styles.module.scss';

const Products = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector((state) => state.app.categoriesWithProducts);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(getCategoriesWithProducts()).then(res => {
            setLoading(false);
        });
    }, [dispatch]);

    return (
        <div>
            <ProductsMainSection />
            <div className={styles.ProductsWrapper}>
                <div className='container'>
                    <h5 className={styles.Title}>Check Out Our</h5>
                    <h4 className={styles.SubTitle}>Products</h4>

                    {loading ? (
                        <div className='text-center mt-5'>
                            <BeatLoader color="#EE3638" />
                        </div>
                    ) : (
                        <div className={styles.CategoryProductsWrapper}>
                            {categories && categories.length > 0 ? (
                                categories.map(category => (
                                    <div className={styles.CategoryItem} key={category.id}>
                                        <h5 className={styles.CategoryName}>{category.name}</h5>
                                        <div className='row'>
                                            {category.limited_products && category.limited_products.length > 0 ? (
                                                category.limited_products.map(product => (
                                                    <div className='col-md-4 mt-3' onClick={() => navigate(`/product/${product.id}`)}>
                                                        {product.image.imageUrl && (
                                                            <img alt='Produkti' src={product.image.imageUrl} className={styles.ProductImage} />
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <p className='text-center'><b>Jemi duke perditesuar produktet, ju lutem na vizitoni perseri!</b></p>
                                            )}
                                        </div>
                                        {category.limited_products && category.limited_products.length > 0 && (
                                            <div className='d-flex justify-content-end'>
                                                <h5 className={styles.MoreButton} onClick={() => navigate(`/all-products/${category.id}`)}>See more &#x2192;</h5>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p className='text-center'><b>Jemi duke perditesuar produktet, ju lutem na vizitoni perseri!</b></p>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Products;