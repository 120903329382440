import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Switch, Tooltip } from '@mui/material';
import { adminStoreProduct, adminStoreProductImage } from '../../../../redux/actions/products';
import styles from '../styles.module.scss';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast } from "react-toastify";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InfoIcon from '@mui/icons-material/Info';

const NewProduct = (props) => {
    const dispatch = useDispatch();
    const { categories, onFinish } = props;

    const [name, setName] = useState();
    const [category, setCategory] = useState();
    const [code, setCode] = useState();
    const [description, setDescription] = useState();
    const [price, setPrice] = useState();
    const [homepage, setHomepage] = useState(false);
    const [shortDescription, setShortDerscription] = useState('');
    const [images, setImages] = useState([{ image: "" }]);

    const [loading, setLoading] = useState(false);

    const onSubmitHandler = useCallback((e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            "name": name,
            "category_id": category,
            "code": code,
            "description": description,
            "price": price,
            "short_description": shortDescription,
            "show_homepage": homepage ? parseInt(1) : parseInt(0)
        };

        dispatch(adminStoreProduct(data)).then(res => {
            if (res.status === 200) {
                toast.success('Produkti u krijua!');
                toast.info('Fotot duke u ngarkuar ne server... Ju lutem prisni!');
                const imgLength = images.length
                let i = 0;
                images.forEach(element => {
                    const formData = new FormData()
                    formData.append('product_id', res.data.product.id)
                    formData.append('image', element.image)
                    dispatch(adminStoreProductImage(formData)).then(res => {
                        i = i + 1;
                        if(i === imgLength){
                            setLoading(false);
                            toast.success('Fotot u ngarkuan!');
                            onFinish();
                        }
                    }).catch(error => {
                        toast.error(`Diqka shkoi keq me ruajtjen e fotos! ${i}`);
                    })
                });
            } else {
                toast.error('Diqka shkoi keq, ju lutem provoni perseri!');
                setLoading(false);
            }
        }).catch(err => {
            console.log("error", err);
            toast.error(err.data.message);
            setLoading(false);
        });
    }, [dispatch, onFinish, category, name, code, description, price, images, shortDescription, homepage]);

    const onChangeDescription = (e, editor) => {
        setDescription(editor.getData())
    }

    const handleChangeImage = (i, e) => {
        let newImages = [...images];
        newImages[i][e.target.name] = e.target.files[0];
        setImages(newImages);
    }
        
    const addNewImageHandler = () => {
        setImages([...images, { image: "" }])
    }
    
    const removeImageHandler = (i) => {
        let newImages = [...images];
        newImages.splice(i, 1);
        setImages(newImages)
    }

    return (
        <form onSubmit={onSubmitHandler} className={styles.NewProductWrapper}>
            <TextField
                label="Produkti"
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
                fullWidth
                value={name}
                size='small'
            />

            <FormControl variant="outlined" fullWidth={true} size="small">
                <InputLabel id="demo-simple-select-outlined-label">Kategoria</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    label="Kategoria"
                    required
                >
                    {categories && categories.map(category => (
                        <MenuItem value={category.id}>{category.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={onChangeDescription}
                config={{         
                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList']
                }}  
            />

            <TextField
                label="Kodi"
                type="text"
                onChange={(e) => setCode(e.target.value)}
                fullWidth
                value={code}
                size='small'
            />

            <TextField
                label="Çmimi"
                type="text"
                onChange={(e) => setPrice(e.target.value)}
                fullWidth
                value={price}
                size='small'
            />

            <div className='d-flex align-items-center'>
                <Switch
                    checked={homepage}
                    onChange={(e) => setHomepage(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />

                <Tooltip title="Shfaqja e produktit ne homepage. Nese shfaqet produkti ne homepage ju gjithashtu duhet te shkruani nje pershkrim te shkurt rreth produktit">
                    <InfoIcon />
                </Tooltip>
            </div>

            {homepage && (
                <TextField
                    label="Pershkrim i shkurter"
                    type="text"
                    onChange={(e) => setShortDerscription(e.target.value)}
                    fullWidth
                    value={shortDescription}
                    size='small'
                    inputProps={{ maxLength: 150 }}
                />
            )}

            {images && images.map((element, index) => (
                <div className="d-flex align-items-center" key={index}>
                    <TextField size="small" required id="outlined-basic" type="file" variant="outlined" name="image" fullWidth={true} onChange={e => handleChangeImage(index, e)} />
                    <div>
                        <AddCircleIcon fontSize="small" color="success" onClick={() => addNewImageHandler()} className="on-hover zoom icon-success" />
                        {
                            index ? 
                            <RemoveCircleIcon fontSize="small" onClick={() => removeImageHandler(index)} className="on-hover zoom icon-danger mm-t" />
                            : null
                        }
                    </div>
                </div>
            ))}

            <div className='text-center'>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Krijo produktin
                </Button>
            </div>

            <div className='text-center'>
                {loading && (
                    <BeatLoader color="#EE3638" />
                )}
            </div>
        </form>
    );
};

export default NewProduct;