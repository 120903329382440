import React, { useState, useEffect } from 'react';
import Footer from '../components/footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/grace-white-logo.png';
import redLogo from '../assets/grace-red-logo.png';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { getProduct } from '../redux/actions/app';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Drawer, Menu, MenuItem } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BeatLoader from 'react-spinners/BeatLoader';

const Product = () => {
    const dispatch = useDispatch();
    const product = useSelector((state) => state.app.product);
    const [drawer, setDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const sxConfig = {
        width: '300px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: '300px',
            boxSizing: 'border-box',
        }
    };

    const changeLanguageHandler = (e) => {
        const languageValue = e;
        i18n.changeLanguage(languageValue);
        setAnchorEl(null);
    }

    useEffect(() => {
        setLoading(true);
        dispatch(getProduct(id)).then(res => {
            setLoading(false);
        })
    }, [id, dispatch])

    return (
        <div className={styles.AboutUsWrapper}>
            <div className='medium'>
                <div className={styles.AboutUsNav}>
                    <div className='container'>
                        <div className={styles.Nav}>
                            <ul className='nav'>
                                <li className='nav-item'>
                                    <Link to="/">
                                        {t('link_home')}
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link to="/products">
                                        {t('link_products')}
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link to="/about-us">
                                        {t('link_about')}
                                    </Link>
                                </li>
                                {/* <li className='nav-item'>
                                    <Link to="#about-us">
                                        {t('link_contact')}
                                    </Link>
                                </li> */}
                            </ul>
                            <div className='navbar-brand text-center'>
                                <Link to="/">
                                    <img src={logo} alt="Grace Logo" />
                                </Link>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <p
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    className='language-dropwdown-button-white'
                                >
                                    {t('language')}: <b>{i18n.language === 'en' ? 'English' : 'Italian'}</b>
                                </p>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => changeLanguageHandler('en')}>English</MenuItem>
                                    <MenuItem onClick={() => changeLanguageHandler('it')}>Italian</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='small'>
                <div className={styles.AboutUsNav}>
                    <div className='container'>
                        <div className={styles.Nav}>
                            <div>
                                <Link to="/">
                                    <img src={logo} alt="Grace Logo" />
                                </Link>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <IconButton  onClick={() => setDrawer((prev) => !prev)}>
                                    {drawer ? (
                                        <CloseIcon htmlColor='#fff' />
                                    ) : (
                                        <MenuIcon htmlColor='#fff' />
                                    )}
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Drawer
                sx={sxConfig}
                anchor="left"
                open={drawer}
                onClose={() => setDrawer(false)}
                
            >
                <div className='grace-drawer'>
                    <img alt="Grace logo" src={redLogo} />
                    <hr />
                    <div className='links'>
                        <Link to="/">
                            {t('link_home')}
                        </Link>
                        <Link to="/products">
                            {t('link_products')}
                        </Link>
                        <Link to="/about-us">
                            {t('link_about')}
                        </Link>
                        {/* <Link to="#about-us">
                            {t('link_contact')}
                        </Link> */}
                        <p
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            className='language-dropwdown-button-black'
                            style={{ paddingLeft: '10px'}}
                        >
                            {t('language')}: <b>{i18n.language === 'en' ? 'English' : 'Italian'}</b>
                        </p>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => changeLanguageHandler('en')}>English</MenuItem>
                            <MenuItem onClick={() => changeLanguageHandler('it')}>Italian</MenuItem>
                        </Menu>
                    </div>
                </div>
            </Drawer>


            <div className='container mt-5'>
                {loading ? (
                    <div className='text-center'>
                        <BeatLoader color="#EE3638" />
                    </div>
                ) : (
                    product && (
                        <div className='row'>
                            <div className='col-md-6'>
                                <Carousel showArrows={true} swipeable="true" autoPlay={false}>
                                    {product && product.images && product.images.length > 0 && product.images.map(img => (
                                        <div className='d-flex align-items-center'>
                                            <img src={`${img.imageUrl}`} alt='imageincarousel' />
                                        </div>
                                    ))}                                
                                </Carousel>
                            </div>
                            <div className='col-md-6'>
                                <h4>{product.name}</h4>
                                <div className='mt-5' style={{color: '#717171', fontSize: '14px'}} dangerouslySetInnerHTML={{__html: product.description}}></div>  
                            </div>
                        </div>
                    )
                )}
            </div>

            <Footer />
        </div>
    );
};

export default Product;