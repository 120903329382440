import React, { useState } from 'react';
import Footer from '../components/footer/Footer';
import logo from '../assets/grace-white-logo.png';
import redLogo from '../assets/grace-red-logo.png';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Drawer, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
    const [drawer, setDrawer] = useState(false);
    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const sxConfig = {
        width: '300px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: '300px',
            boxSizing: 'border-box',
        }
    };

    const changeLanguageHandler = (e) => {
        const languageValue = e;
        i18n.changeLanguage(languageValue);
        setAnchorEl(null);
    }

    return (
        <div className={styles.AboutUsWrapper}>
            <div className='medium'>
                <div className={styles.AboutUsNav}>
                    <div className='container'>
                        <div className={styles.Nav}>
                            <ul className='nav'>
                                <li className='nav-item'>
                                    <Link to="/">
                                        {t('link_home')}
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link to="/products">
                                        {t('link_products')}
                                    </Link>
                                </li>
                                <li className='nav-item'>
                                    <Link to="/about-us">
                                        {t('link_about')}
                                    </Link>
                                </li>
                                {/* <li className='nav-item'>
                                    <Link to="#about-us">
                                        {t('link_contact')}
                                    </Link>
                                </li> */}
                            </ul>
                            <div className='navbar-brand text-center'>
                                <Link to="/">
                                    <img src={logo} alt="Grace Logo" />
                                </Link>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <p
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    className='language-dropwdown-button-white'
                                >
                                    {t('language')}: <b>{i18n.language === 'en' ? 'English' : 'Italian'}</b>
                                </p>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => changeLanguageHandler('en')}>English</MenuItem>
                                    <MenuItem onClick={() => changeLanguageHandler('it')}>Italian</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='small'>
                <div className={styles.AboutUsNav}>
                    <div className='container'>
                        <div className={styles.Nav}>
                            <div>
                                <Link to="/">
                                    <img src={logo} alt="Grace Logo" />
                                </Link>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <IconButton  onClick={() => setDrawer((prev) => !prev)}>
                                    {drawer ? (
                                        <CloseIcon htmlColor='#fff' />
                                    ) : (
                                        <MenuIcon htmlColor='#fff' />
                                    )}
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Drawer
                sx={sxConfig}
                anchor="left"
                open={drawer}
                onClose={() => setDrawer(false)}
                
            >
                <div className='grace-drawer'>
                    <img alt="Grace logo" src={redLogo} />
                    <hr />
                    <div className='links'>
                        <Link to="/">
                            {t('link_home')}
                        </Link>
                        <Link to="/products">
                            {t('link_products')}
                        </Link>
                        <Link to="/about-us">
                            {t('link_about')}
                        </Link>
                        {/* <Link to="#about-us">
                            {t('link_contact')}
                        </Link> */}
                        <p
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            className='language-dropwdown-button-black'
                            style={{ paddingLeft: '10px'}}
                        >
                            {t('language')}: <b>{i18n.language === 'en' ? 'English' : 'Italian'}</b>
                        </p>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => changeLanguageHandler('en')}>English</MenuItem>
                            <MenuItem onClick={() => changeLanguageHandler('it')}>Italian</MenuItem>
                        </Menu>
                    </div>
                </div>
            </Drawer>


            <div className='container mt-5'>
                <div className={styles.AboutUsItem}>
                    <h2 className={styles.AboutUsItemTitle}>Who We Are</h2>
                    <p className={styles.AboutUsItemDescription}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                    </p>
                    <p className={styles.AboutUsItemDescription}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                    </p>
                </div>

                <div className={styles.AboutUsItem}>
                    <h2 className={styles.AboutUsItemTitle}>What We Do</h2>
                    <p className={styles.AboutUsItemDescription}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                    </p>
                    <p className={styles.AboutUsItemDescription}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                    </p>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default AboutUs;