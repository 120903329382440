import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Container, TextField, Button } from '@mui/material';
import { login } from '../../redux/actions/auth';
import styles from './styles.module.scss';
import { toast } from "react-toastify";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onLoginFormHandler = (e) => {
        e.preventDefault();
        setLoading(false);   
        dispatch(login(email, password)).then(res => {
            setLoading(true);
            toast.success("Jeni kyqur me sukses!");
        }).catch(err => {
            setLoading(false);
            if(err.data.message === "Invalid credentials"){
                toast.error("Kredenciale të gabuara!");
            }else{
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            }
        });     
    };

    useEffect(() => {
        if (user) {
            navigate('/dashboard');
        }
    }, [user, navigate])

    return (
        <Container className={styles.LoginWrapper}>
            <form className={styles.FormWrapper} onSubmit={onLoginFormHandler}>
                <h4>LOGIN</h4>
                {loading && <p>asd</p>}
                <TextField
                    className={styles.textField}
                    label="Email"
                    type="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    className={styles.textField}
                    label="Password"
                    type="password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    className={styles.submitButton}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Login
                </Button>
            </form>
        </Container>
    );
};

export default Login;