export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const TOGGLE_LOADING_FORM = "TOGGLE_LOADING_FORM";

export const ADMIN_SET_CATEGORIES = "ADMIN_SET_CATEGORIES";
export const ADMIN_SET_PRODUCTS = "ADMIN_SET_PRODUCTS";
export const ADMIN_SET_PRODUCT = "ADMIN_SET_PRODUCT";
export const ADMIN_CHANGE_PRODUCT_STATUS = "ADMIN_CHANGE_PRODUCT_STATUS";
export const ADMIN_CHANGE_PRODUCT_HOMEPAGE = "ADMIN_CHANGE_PRODUCT_HOMEPAGE";
export const ADMIN_FETCH_MESSAGES = "ADMIN_FETCH_MESSAGES";

export const FETCH_CATEGORIES_WITH_PRODUCTS = "FETCH_CATEGORIES_WITH_PRODUCTS";
export const FEETCH_CATEGORIES = "FEETCH_CATEGORIES";
export const FETCH_HOMEPAGE_PRODUCTS = "FETCH_HOMEPAGE_PRODUCTS";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCT = "FETCH_PRODUCT";