import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import styles from './styles.module.scss';
import logoRed from '../../assets/grace-red-logo.png';
import { useDispatch } from 'react-redux';
import { storeMessage } from '../../redux/actions/messages';
import { toast } from "react-toastify";

const Footer = () => {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const onSubmitContactForm = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            "fullname": name,
            "email": email,
            "phone": phone,
            "message": message
        }
        dispatch(storeMessage(data)).then(res => {
            toast.success('Your message has been sent successfully!');
            setLoading(false);
            setName('')
            setEmail('')
            setPhone('')
            setMessage('')
        })
    }

    return (
        <div className={styles.FooterWrapper}>
            <div className='container'>
                <div className={styles.Footer}>
                    <img alt="Logo Grace" src={logoRed} className={styles.LogoFooter} />
                    <div className={styles.ContactFormWrapper}>
                        <h4>Contact Us</h4>
                        <form className={styles.ContactForm} onSubmit={onSubmitContactForm}>
                            <TextField
                                label="Full name"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                fullWidth
                                required
                                value={name}
                                size='small'
                            />
                            <div className='row'>
                                <div className='col-md-6'>
                                    <TextField
                                        label="Email"
                                        type="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        fullWidth
                                        value={email}
                                        size='small'
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <TextField
                                        label="Phone"
                                        type="text"
                                        onChange={(e) => setPhone(e.target.value)}
                                        fullWidth
                                        value={phone}
                                        required
                                        size='small'
                                        className={styles.PhoneInput}
                                    />
                                </div>
                            </div>
                            <TextField
                                label="Message"
                                type="text"
                                onChange={(e) => setMessage(e.target.value)}
                                fullWidth
                                required
                                value={message}
                                rows={2}
                                multiline
                                size='small'
                            />
                            <div className='text-center mt-2'>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={loading}
                                >
                                    Send message
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <p className='text-center mt-3'>Grace All rights reserved © 2023</p>
            </div>
        </div>
    );
};

export default Footer;