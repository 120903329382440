import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportIcon from '@mui/icons-material/Report';
import { IconButton, Button, Tooltip, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Switch, Pagination } from '@mui/material';
import { toggleDrawer } from '../../../redux/actions/app';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import { adminFetchProducts, adminDeleteProduct, adminChangeProductStatus, adminChangeProductShowHomepage } from '../../../redux/actions/products';
import { adminFetchCategories } from '../../../redux/actions/categories';
import CollectionsIcon from '@mui/icons-material/Collections';
import styles from './styles.module.scss';
import BeatLoader from 'react-spinners/BeatLoader';
import NewProduct from './components/NewProduct';
import EditProduct from './components/EditProduct';
import Gallery from './components/Gallery';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Transition from '../../../components/Transition';
import { toast } from "react-toastify";

const AdminProducts = () => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products);
    const categories = useSelector((state) => state.categories.categories);
    const [params, setParams] = useState({ page: 1, offer: 0, status: 'all', stock: 'all', category: 'all', name: "" });

    const [form, setForm] = useState('');
    const [selectedItem, setSelectedItem] = useState();
    const [deleteModal, setDeleteModal] = useState();
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const onNewButtonClick = useCallback(() => {
        setForm('new')
        dispatch(toggleDrawer(true));
    }, [dispatch]);

    const fetchData = useCallback(() => {
        setLoading(true);
        dispatch(adminFetchProducts(params)).then(res => {
            dispatch(adminFetchCategories()).then(res => {
                setLoading(false);
            })
        });
    }, [dispatch, params]);

    const handleChangePagination = (event, value) => {
        setParams({ ...params, page: value });
    };

    useEffect(() => {
        fetchData();
    }, [fetchData, params]);

    const onFinish = useCallback(() => {
        dispatch(toggleDrawer(false));
        fetchData();
        setSelectedItem();
    }, [dispatch, fetchData]);

    const onEditClick = useCallback((item) => {
        setForm('edit')
        setSelectedItem(item);
        dispatch(toggleDrawer(true));
    }, [dispatch]);

    const openGalleryProduct = (item) => {
        setForm('gallery');
        setSelectedItem(item);
        dispatch(toggleDrawer(true));
    }

    const onDeleteClick = useCallback((item) => {
        setSelectedItem(item);
        setDeleteModal(true);
    }, []);

    const onSubmitDeleteHandler = useCallback(() => {
        setDeleteLoading(true);
        dispatch(adminDeleteProduct(selectedItem.id)).then(res => {
            setDeleteLoading(false);
            setDeleteModal(false);
            setSelectedItem();
            toast.success('Produkti u fshi me sukses!');
            fetchData();
        }).catch(err => {
            setDeleteLoading(false);
            toast.error('Diqka shkoi keq. Ju lutem provoni perseri!');
        })
    }, [dispatch, selectedItem, fetchData]);

    const onSubmitChangeStatus = useCallback((item, value) => {
        const data = {
            'status': parseInt(value)
        }
        dispatch(adminChangeProductStatus(data, item.id)).then(res => {
            toast.success("Statusi u ndryshua!")
        }).catch(err => {
            toast.error('Diqka shkoi keq, ju lutem provoni perseri!');
        })
    }, [dispatch]);

    const onSubmitChangeShowHomepage = useCallback((item, value) => {
        const data = {
            'show_homepage': parseInt(value)
        }
        dispatch(adminChangeProductShowHomepage(data, item.id)).then(res => {
            toast.success("Shfaqja e produktit ne homepage u ndryshua!")
        }).catch(err => {
            toast.error('Diqka shkoi keq, ju lutem provoni perseri!');
        })
    }, [dispatch]);

    return (
        <div className={styles.ProductsWrapper}>
            <div className='container'>
                <div className='d-flex justify-content-between align-items-center'>
                    <p className={styles.PageTitle}>Produktet</p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onNewButtonClick}
                    >
                        Krijo produkt
                    </Button>
                </div>

                <hr></hr>

                {loading ? (
                    <div className='text-center'>
                        <BeatLoader color="#EE3638" />
                    </div>
                ) : (
                    <div className={styles.ProductsListWrapper}>
                        {products && products.data && products.data.length > 0 ? (
                            <div>
                                <TableContainer >
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center"><b>Foto</b></TableCell>
                                                <TableCell align="center"><b>Produkti</b></TableCell>
                                                <TableCell align="center"><b>Kodi</b></TableCell>
                                                <TableCell align="center"><b>Çmimi</b></TableCell>
                                                <TableCell align="center"><b>Status</b></TableCell>
                                                <TableCell align="center"><b>Homepage</b></TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {products && products.data && products.data.map((product, index) => (
                                                <TableRow className={styles.TableRowHover} key={index}>
                                                    <TableCell align="center">
                                                        {product.image  && (
                                                            <img alt={`${product.name}-img`} src={product.image.imageUrl} width="50px" />
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">{product.name.substring(0, 30)}</TableCell>
                                                    <TableCell align="center">{product.code}</TableCell>
                                                    <TableCell align='center'>{product.price} €</TableCell>
                                                    <TableCell align='center'>
                                                        <Tooltip title={product.status === 1 ? 'Statusi i produktit behet inaktiv dhe klientet nuk mund ta shohin' : 'Statusi i produktit behet aktiv dhe klientet mund ta shohin'} placement='top'>
                                                            <Switch
                                                                checked={product.status === 1 ? true : false}
                                                                onChange={() => onSubmitChangeStatus(product, product.status === 1 ? 0 : 1)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Tooltip title={product.show_homepage === 1 ? 'Produkti nuk do shfaqet ne homepage' : 'Produkti shfaqet ne homepage. Nese aktivizoni ju lutem editoni produktin dhe vendosni nje pershkrim te shkurter te produktit.'} placement='top'>
                                                            <Switch
                                                                checked={product.show_homepage === 1 ? true : false}
                                                                onChange={() => onSubmitChangeShowHomepage(product, product.show_homepage === 1 ? 0 : 1)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className='d-flex justify-content-end'>
                                                            <Tooltip title="Fotot e produktit" placement='top'>
                                                                <IconButton onClick={() => openGalleryProduct(product)}>
                                                                    <CollectionsIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Ndrysho kategorine" placement='top'>
                                                                <IconButton onClick={() => onEditClick(product)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Fshije kategorine" placement='top'>
                                                                <IconButton onClick={() => onDeleteClick(product)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {products.last_page > 1 && (
                                    <div className="d-flex justify-content-center mt-5">
                                        <Pagination count={products.last_page} page={params.page} size="small" onChange={handleChangePagination} color="primary" />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className='text-center'>
                                <p>Nuk u gjet asnje produkt. Ju lutem krijoni produktet!</p>
                            </div>
                        )}
                    </div>
                )}

                <Dialog
                    open={deleteModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setDeleteModal(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle align="center"><ReportIcon /></DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description" className='text-center'>
                                A jeni te sigurte per fshirjen e ketij produkti?
                                {deleteLoading && (
                                    <BeatLoader color="#EE3638" className='mt-3' />
                                )}
                            </DialogContentText>
                        </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteModal(false)}>
                            Anulo
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => onSubmitDeleteHandler()}>
                            Fshije
                        </Button>
                    </DialogActions>
                </Dialog>

                <RightDrawer title={form === 'new' ? "Krijo produkt" : form === 'edit' ? "Ndrysho produktin" : 'Fotot e produktit'}>
                    {form === 'new' && <NewProduct onFinish={onFinish} categories={categories} />}
                    {form === 'edit' && <EditProduct product={selectedItem} onFinish={onFinish} categories={categories} />}
                    {form === 'gallery' && <Gallery product={selectedItem} />}
                </RightDrawer>
            </div>
        </div>
    );
};

export default AdminProducts;