import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { authReducer } from './reducers/auth';
import { appReducer } from './reducers/app';
import { categoriesReducer } from './reducers/categories'; 
import { productsReducer } from './reducers/products';
import { messagesReducer } from './reducers/messages';

const mainReducer = combineReducers({
    auth: authReducer,
    app: appReducer,
    categories: categoriesReducer,
    products: productsReducer,
    messages: messagesReducer
});

const middleware = [thunk]

const tokenFromStorage = localStorage.getItem('token')
    ? JSON.parse(localStorage.getItem('token'))
    : null

const userFromStorage = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null

const initialState = {
    auth: { token: tokenFromStorage, user: userFromStorage }
}


const store = createStore(
    mainReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store; 