import * as c from "../constants";
import API from '../api';

export const toggleDrawer = (value) => ({
    type: c.TOGGLE_DRAWER,
    payload: value
})

export const toggleLoading = (value) => ({
    type: c.TOGGLE_LOADING_FORM,
    payload: value
})

export const getCategoriesWithProducts = () => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getCategoriesWithProducts`, config)

        dispatch({ type: c.FETCH_CATEGORIES_WITH_PRODUCTS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const getCategories = () => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getCategories`, config)

        dispatch({ type: c.FEETCH_CATEGORIES, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const getProducts = (id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getProducts/${id}`, config)

        dispatch({ type: c.FETCH_PRODUCTS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const getProduct = (id) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getProduct/${id}`, config)

        dispatch({ type: c.FETCH_PRODUCT, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const getHomepageProducts = () => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/getHomepageProducts`, config)

        dispatch({ type: c.FETCH_HOMEPAGE_PRODUCTS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}