import API from '../api';
import * as c from "../constants";

export const adminFetchCategories = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/categories`, config)

        dispatch({ type: c.ADMIN_SET_CATEGORIES, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const adminStoreCategory = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/categories`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const adminPutCategory = (data, id) => async(dispatch, getState) => {
    console.log("Data", data);
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/update-categories/${id}`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const adminDeleteCategory = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/categories/${id}`, config);

        return response
    } catch (error) {
        throw error
    }
}