import API from '../api';
import * as c from "../constants";

export const adminFetchProducts = (params) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/products?page=${params.page}`, config)

        dispatch({ type: c.ADMIN_SET_PRODUCTS, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const adminFetchProduct = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/products/${id}`, config)

        dispatch({ type: c.ADMIN_SET_PRODUCT, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const adminChangeProductStatus = (data, id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/changeProductStatus/${id}`, data, config)

        dispatch({ type: c.ADMIN_CHANGE_PRODUCT_STATUS, productId: id, status: data.status })

        return response
    } catch (error) {
        throw error
    }
}

export const adminChangeProductShowHomepage = (data, id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/changeProductShowHomepage/${id}`, data, config)

        dispatch({ type: c.ADMIN_CHANGE_PRODUCT_HOMEPAGE, productIdd: id, homepage: data.show_homepage })

        return response
    } catch (error) {
        throw error
    }
}

export const adminStoreProduct = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/products`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const adminPutProduct = (data, id) => async(dispatch, getState) => {
    console.log("Data", data);
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.put(`/products/${id}`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const adminDeleteProduct = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/products/${id}`, config);

        return response
    } catch (error) {
        throw error
    }
}

export const adminStoreProductImage = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/image-product`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const adminDeleteProductImage = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/image-product/${id}`, config);

        return response
    } catch (error) {
        throw error
    }
}