import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Button } from '@mui/material';
import { adminPutCategory } from '../../../../redux/actions/categories';
import styles from '../styles.module.scss';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast } from "react-toastify";

const EditCategory = (props) => {
    const { onFinish, category } = props;
    const dispatch = useDispatch();

    const [name, setName] = useState(category ? category.name : '');
    const [order, setOrder] = useState(category ? category.order_number : 0);
    const [image, setImage] = useState();
    const [loading, setLoading] = useState(false);

    const onSubmitHandler = useCallback((e) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData();
        data.append('name', name);
        data.append('order_number', order);
        if (image) {
            data.append('image', image);
        }

        dispatch(adminPutCategory(data, category.id)).then(res => {
            setLoading(false);
            toast.success('Kategoria u ndryshua!');
            onFinish();
        }).catch(err => {
            console.log("error", err);
            toast.error(err.data.message);
            setLoading(false);
        });
    }, [image, name, dispatch, onFinish, category, order]);

    return (
        <form onSubmit={onSubmitHandler} className={styles.NewCategoryWrapper}>
            <TextField
                label="Kategoria"
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
                fullWidth
                value={name}
                size='small'
            />

            <TextField
                label="Renditja"
                type="number"
                required
                onChange={(e) => setOrder(e.target.value)}
                fullWidth
                value={order}
                size='small'
            />

            <TextField
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                fullWidth
                size='small'
            />

            <div className='text-center'>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Ndrysho kategorine
                </Button>
            </div>

            <div className='text-center'>
                {loading && (
                    <BeatLoader color="#EE3638" />
                )}
            </div>
        </form>
    );
};

export default EditCategory;