import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Container, TextField, Button } from '@mui/material';
import { login } from '../../redux/actions/auth';
import styles from './styles.module.scss';
import { toast } from "react-toastify";

const Dashboard = () => {

    return (
        <p></p>
    );
};

export default Dashboard;