import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adminFetchMessages } from '../../../redux/actions/messages';
import styles from './styles.module.scss';
import BeatLoader from 'react-spinners/BeatLoader';

const AdminMessages = () => {
    const dispatch = useDispatch();
    const messages = useSelector((state) => state.messages.messages);
    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(() => {
        setLoading(true);
        dispatch(adminFetchMessages()).then(res => {
            setLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className={styles.MessagesWrapper}>
            <div className='container'>

                {loading ? (
                    <div className='text-center'>
                        <BeatLoader color="#EE3638" />
                    </div>
                ) : (
                    <div className={styles.MessagesListWrapper}>
                        {messages && messages.length > 0 ? (
                            messages.map(message => (
                                <div className={styles.MessageItem} key={message.id}>
                                    <div className='d-flex justify-content-around'>
                                        <p>Emri: <b>{message.fullname}</b></p>
                                        <p>Telefoni: <b>{message.phone}</b></p>
                                        <p>Email: <b>{message.email}</b></p>
                                    </div>
                                    <p>Mesazhi: <b>{message.message}</b></p>
                                </div>
                            ))
                        ) : (
                            <div className='text-center'>
                                <p>Nuk u gjet asnje mesazh!</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminMessages;