import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportIcon from '@mui/icons-material/Report';
import { IconButton, Button, Tooltip, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@mui/material';
import { toggleDrawer } from '../../../redux/actions/app';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import { adminFetchCategories, adminDeleteCategory } from '../../../redux/actions/categories';
import styles from './styles.module.scss';
import NewCategory from './components/NewCategory';
import EditCategory from './components/EditCategory';
import BeatLoader from 'react-spinners/BeatLoader';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Transition from '../../../components/Transition';
import { toast } from "react-toastify";

const AdminCategories = () => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.categories.categories);

    const [form, setForm] = useState('');
    const [selectedItem, setSelectedItem] = useState();
    const [deleteModal, setDeleteModal] = useState();
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const onNewCategoryButtonClick = useCallback(() => {
        setForm('new')
        dispatch(toggleDrawer(true));
    }, [dispatch]);

    const fetchData = useCallback(() => {
        setLoading(true);
        dispatch(adminFetchCategories()).then(res => {
            setLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onFinish = useCallback(() => {
        dispatch(toggleDrawer(false));
        fetchData();
        setSelectedItem();
    }, [dispatch, fetchData]);

    const onEditClick = useCallback((category) => {
        setForm('edit')
        setSelectedItem(category);
        dispatch(toggleDrawer(true));
    }, [dispatch]);

    const onDeleteClick = useCallback((category) => {
        setSelectedItem(category);
        setDeleteModal(true);
    }, []);

    const onSubmitDeleteHandler = useCallback(() => {
        setDeleteLoading(true);
        dispatch(adminDeleteCategory(selectedItem.id)).then(res => {
            setDeleteLoading(false);
            setDeleteModal(false);
            setSelectedItem();
            toast.success('Kategoria u fshi me sukses!');
            fetchData();
        }).catch(err => {
            setDeleteLoading(false);
            toast.error('Diqka shkoi keq. Ju lutem provoni perseri!');
        })
    }, [dispatch, selectedItem, fetchData]);

    return (
        <div className={styles.CategoriesWrapper}>
            <div className='container'>
                <div className='d-flex justify-content-between align-items-center'>
                    <p className={styles.PageTitle}>Kategorite</p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onNewCategoryButtonClick}
                    >
                        Krijo kategori
                    </Button>
                </div>

                <hr></hr>

                {loading ? (
                    <div className='text-center'>
                        <BeatLoader color="#EE3638" />
                    </div>
                ) : (
                    <div className={styles.CategoriesListItemsWrapper}>
                        {categories && categories.length > 0 ? (
                            categories.map(category => (
                                <div className={styles.CategoryItem} key={category.id}>
                                    <div className='d-flex align-items-center'>
                                        <img alt={category.name} src={category.imageUrl} />
                                        <p className={styles.CategoryName}>{category.name}</p>
                                    </div>
                                    <p>{category.order_number}</p>
                                    <div className='d-flex justify-content-end'>
                                        <Tooltip title="Ndrysho kategorine" placement='top'>
                                            <IconButton onClick={() => onEditClick(category)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Fshije kategorine" placement='top'>
                                            <IconButton onClick={() => onDeleteClick(category)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className='text-center'>
                                <p>Nuk u gjet asnje kategori. Ju lutem krijoni kategorite!</p>
                            </div>
                        )}
                    </div>
                )}

                <Dialog
                    open={deleteModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setDeleteModal(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle align="center"><ReportIcon /></DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description" className='text-center'>
                                Fshirja e kësaj kategorie rezulton gjithashtu edhe në fshirjen e gjithë produkteve që bëjnë pjesë në këtë kategori.
                                {deleteLoading && (
                                    <BeatLoader color="#EE3638" className='mt-3' />
                                )}
                            </DialogContentText>
                        </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteModal(false)}>
                            Anulo
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => onSubmitDeleteHandler()}>
                            Fshije
                        </Button>
                    </DialogActions>
                </Dialog>

                <RightDrawer title={form === 'new' ? "Krijo kategori" : "Ndrysho kategorine"}>
                    {form === 'new' && <NewCategory onFinish={onFinish} />}
                    {form === 'edit' && <EditCategory category={selectedItem} onFinish={onFinish} />}
                </RightDrawer>
            </div>
        </div>
    );
};

export default AdminCategories;