import React from 'react';
import img1 from '../../assets/our-mission-1.png';
import img2 from '../../assets/our-mission-2.png';
import img3 from '../../assets/our-mission-3.png';
import styles from './styles.module.scss';

const OurMission = () => {
    return (
        <div className={styles.OurMissionWrapper}>

            <div className='text-center container'>
                <h6>
                    We Make Your Home Better.
                </h6>
                <div className={styles.Images}>
                    <img alt='Our mission img 1' src={img1} />
                    <img alt='Our mission img 2' src={img2} />
                    <img alt='Our mission img 3' src={img3} />
                </div>
                <h1>
                    The goal of Grace is to prevent the spread of disease and promote a safe and healthy living environment for all individuals.
                </h1>
            </div>
        </div>
    );
};

export default OurMission;