import * as c from "../constants";

const initialState = () => {
    return {
        messages: []
    }
}

export const messagesReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.ADMIN_FETCH_MESSAGES:
            return { ...state, messages: action.payload }
        default:
            return state
    }
}
