import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip, IconButton } from '@mui/material';
import { adminFetchProduct, adminStoreProductImage, adminDeleteProductImage } from '../../../../redux/actions/products';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast } from "react-toastify";

const Gallery = (props) => {
    const dispatch = useDispatch();
    const { product } = props;
    const productState = useSelector((state) => state.products.product);

    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(() => {
        setLoading(true);
        dispatch(adminFetchProduct(product.id)).then(res => {
            setLoading(false);
        })
    }, [dispatch, product]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const uploadImage = (e) => {
        setLoading(true);
        const formData = new FormData()
        formData.append('product_id', product.id);
        formData.append('image', e.target.files[0]);
        dispatch(adminStoreProductImage(formData)).then(res => {
            toast.success("Foto u shtua me sukses!")
            fetchData();
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            toast.error("Dicka shkoi keq, ju lutem provoni perseri!")
        });
    }

    const deleteImage = (img) => {
        setLoading(false);
        dispatch(adminDeleteProductImage(img.id)).then(res => {
            fetchData();
            toast.success("Foto u fshi me sukses");
        }).catch(e => {
            toast.error("Dicka shkoi keq, ju lutem provoni perseri");
        })
    }

    return (
        <div>
            {loading ? (
                <div className='text-center'>
                    <BeatLoader color="#EE3638" />
                </div>
            ) : (
                <div>
                    <div className='d-flex justify-content-end'>
                        <Button 
                            variant="contained" 
                            component="label"
                            fontSize="small"
                        >
                            Shto foto <AddPhotoAlternateIcon className='ms-1' fontSize="small" />
                            <input
                                type="file"
                                hidden
                                onChange={uploadImage}
                            />
                        </Button>
                    </div>
                    <div>
                        {productState && productState.images && productState.images.map(img => (
                            <div>
                                <hr></hr>
                                <div className='d-flex justify-content-around'>
                                    <div className='text-center'>
                                        <img alt='Product_img' src={img.imageUrl} width="50%" />
                                    </div>  
                                    <div>
                                        <Tooltip title="Fshije foton" placement="top">
                                            <IconButton onClick={() => deleteImage(img)}>
                                                <DeleteIcon fontSize="small" color="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Gallery;