import * as c from "../constants";

const initialState = () => {
    return {
        isRightDrawerOpen: false,
        loading: false,
        categoriesWithProducts: [],
        categories: [],
        homepage_products: [],
        products: [],
        product: null,
    }
}

export const appReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.TOGGLE_DRAWER:
            return { ...state, isRightDrawerOpen: action.payload }
        case c.TOGGLE_LOADING_FORM:
            return { ...state, loading: action.payload }
        case c.FETCH_CATEGORIES_WITH_PRODUCTS:
            return { ...state, categoriesWithProducts: action.payload }
        case c.FEETCH_CATEGORIES:
            return { ...state, categories: action.payload }
        case c.FETCH_HOMEPAGE_PRODUCTS:
            return { ...state, homepage_products: action.payload }
        case c.FETCH_PRODUCTS:
            return { ...state, products: action.payload }
        case c.FETCH_PRODUCT:
            return { ...state, product: action.payload }
        default:
            return state
    }
}
