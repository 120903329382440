import React, { useState, useEffect }  from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHomepageProducts } from "../../redux/actions/app";
import BeatLoader from 'react-spinners/BeatLoader';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';


const InspirationsDesign = () => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.app.homepage_products);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(getHomepageProducts()).then(res => {
            setLoading(false);
        });
    }, [dispatch]);

    return (
        <div className={styles.InspirationsDesignWrapper}>
            <div className="container">
                <div className="text-center">
                    <h6>
                        Inspiration Design
                    </h6>
                    <h4>
                        Custom Furniture For Minimalism Life, House Creative and Unique
                    </h4>
                </div>
                <div className={styles.InspirationsDesignItems}>
                    {loading ? (
                        <div className='text-center mt-5'>
                            <BeatLoader color="#EE3638" />
                        </div>
                    ) : (
                        <div className="row flex-nowrap overflow-auto">
                            {products && products.length > 0 ? (
                                products.map(product => (
                                    <div className="col-md-3" onClick={() => navigate(`/product/${product.id}`)}>
                                        <div className={styles.InspirationsDesignItem}>
                                            <img alt="Our-product-1" src={product.image.imageUrl} className={styles.ProductImage}/>
                                            <h3>{product.name}</h3>
                                            <div className={styles.InspirationsDesignItemDescription}>
                                                <p>{product.short_description}</p>
                                            </div>
                                            <hr></hr>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className='text-center my-5'><b>Jemi duke perditesuar produktet, ju lutem na vizitoni perseri!</b></p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InspirationsDesign;