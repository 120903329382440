import * as c from "../constants";

const initialState = () => {
    return {
        categories: []
    }
}

export const categoriesReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.ADMIN_SET_CATEGORIES:
            return { ...state, categories: action.payload }
        default:
            return state
    }
}
