import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AdminRoute from './routes/AdminRoute';

import Home from './pages/Home';
import Products from './pages/Products';
import AboutUs from './pages/AboutUs';
import AllProducts from './pages/AllProducts';
import Product from './pages/Product';

import Login from './pages/login/Login';
import Dashboard from './pages/admin/Dashboard';
import AdminMessages from './pages/admin/messages/Messages';
import AdminCategories from './pages/admin/categories/Categories';
import AdminProducts from './pages/admin/products/Products';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/products" exact element={<Products />} />
        <Route path="/all-products/:id" element={<AllProducts />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/about-us" exact element={<AboutUs />} />
        <Route path="/login" exact element={<Login />} />

        <Route path="/dashboard" exact element={<AdminRoute><Dashboard /></AdminRoute>} />
        <Route path="/admin-categories" exact element={<AdminRoute><AdminCategories /></AdminRoute>} />
        <Route path="/admin-messages" exact element={<AdminRoute><AdminMessages /></AdminRoute>} />
        <Route path="/admin-products" exact element={<AdminRoute><AdminProducts /></AdminRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
