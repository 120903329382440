import React from 'react';
import styles from "./right-drawer.module.scss";
import {useDispatch, useSelector} from "react-redux";
import { toggleDrawer } from '../../redux/actions/app';
import {Drawer, IconButton, styled, CircularProgress} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const RightDrawerHeader = styled('div')(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 2, 0, 2),
    zIndex: theme.zIndex.drawer + 2
}));

const RightDrawerContainer = styled('div')(({theme}) => ({
    padding: theme.spacing(2),
}));

const RightDrawer = (props) => {

    const dispatch = useDispatch();
    const isDrawerOpen = useSelector((state) => state.app.isRightDrawerOpen);
    const loading = useSelector((state) => state.app.loading);
    const isMobile = window.innerWidth < 500;

    const sxConfig = {
        width: isMobile ? '100vw' : '400px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: isMobile ? '100vw' : '400px',
            boxSizing: 'border-box',
        }
    };

    const handleCloseDrawer = () => {
        dispatch(toggleDrawer(false));
        props.onClose && props.onClose();
    }

    return (
        <Drawer
            sx={sxConfig}
            anchor="right"
            open={isDrawerOpen}
            onClose={handleCloseDrawer}>
            {!props.hideHeader && (
                <>
                    <RightDrawerHeader>
                        <div className={styles.drawerTitle}>
                            {props.title}
                        </div>
                        {loading ? 
                            <CircularProgress size="20px" /> : 
                            <IconButton aria-label="delete" size="small" onClick={handleCloseDrawer}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                    </RightDrawerHeader>
                    <hr></hr>
                </>
            )}
            <RightDrawerContainer style={{marginTop: "-20px"}}>
                {props.children}
            </RightDrawerContainer>
        </Drawer>
    );
}

export default RightDrawer;