import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/grace-white-logo.png';
import redLogo from '../../assets/grace-red-logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Drawer, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next'
import './mainsection.css';

const ProductsMainSection = () => {
    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguageHandler = (e) => {
        const languageValue = e;
        i18n.changeLanguage(languageValue);
        setAnchorEl(null);
    }

    const sxConfig = {
        width: '300px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: '300px',
            boxSizing: 'border-box',
        }
    };

    const [drawer, setDrawer] = useState(false);

    return(
        <div className='products-main-section'>
            <div className='banner home-banner medium'>
                <div className='container'>
                    <nav className='grace-navbar'>
                        <ul className='nav'>
                            <li className='nav-item'>
                                <Link to="/">
                                    {t('link_home')}
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to="/products">
                                    {t('link_products')}
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to="/about-us">
                                    {t('link_about')}
                                </Link>
                            </li>
                            {/* <li className='nav-item'>
                                <Link to="#about-us">
                                    {t('link_contact')}
                                </Link>
                            </li> */}
                        </ul>
                        <div className='navbar-brand text-center'>
                            <Link to="/">
                                <img src={logo} alt="Grace Logo" />
                            </Link>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <p
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                className='language-dropwdown-button-white'
                            >
                                {t('language')}: <b>{i18n.language === 'en' ? 'English' : 'Italian'}</b>
                            </p>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => changeLanguageHandler('en')}>English</MenuItem>
                                <MenuItem onClick={() => changeLanguageHandler('it')}>Italian</MenuItem>
                            </Menu>
                        </div>
                    </nav>
                    <div className='text-main-wrapper'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <h1>We Make Your Home Better</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='banner home-banner small'>
                <div className='container'>
                    <nav className='grace-navbar-small'>
                        <div>
                            <Link to="/">
                                <img src={logo} alt="Grace Logo" />
                            </Link>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <IconButton  onClick={() => setDrawer((prev) => !prev)}>
                                {drawer ? (
                                    <CloseIcon htmlColor='#fff' />
                                ) : (
                                    <MenuIcon htmlColor='#fff' />
                                )}
                            </IconButton>
                        </div>
                    </nav>
                    <div className='text-main-wrapper'>
                        <h1>We Make Your Home Better</h1>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                    </div>
                </div>

                <Drawer
                    sx={sxConfig}
                    anchor="left"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    
                >
                    <div className='grace-drawer'>
                        <img alt="Grace logo" src={redLogo} />
                        <hr />
                        <div className='links'>
                            <Link to="/">
                                {t('link_home')}
                            </Link>
                            <Link to="/products">
                                {t('link_products')}
                            </Link>
                            <Link to="/about-us">
                                {t('link_about')}
                            </Link>
                            {/* <Link to="#about-us">
                                {t('link_contact')}
                            </Link> */}
                            <p
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                className='language-dropwdown-button-black'
                                style={{ paddingLeft: '10px'}}
                            >
                                {t('language')}: <b>{i18n.language === 'en' ? 'English' : 'Italian'}</b>
                            </p>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => changeLanguageHandler('en')}>English</MenuItem>
                                <MenuItem onClick={() => changeLanguageHandler('it')}>Italian</MenuItem>
                            </Menu>
                        </div>
                    </div>
                </Drawer>
            </div>
        </div>
    );
}

export default ProductsMainSection;