import * as c from "../constants";
import API from '../api';

export const storeMessage = (data) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.post(`/message`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const adminFetchMessages = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get('/message', config)

        dispatch({ type: c.ADMIN_FETCH_MESSAGES, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}
